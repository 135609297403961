.link-text {
  color: $primary;

  &:hover {
    text-decoration: underline;
    color: darken($color: $primary, $amount: 10);
    transition: all 200ms linear;
  }
}

.text {
  &-md {
    font-size: 20px;
  }

  &-dark {
    color: $text-dark;
  }
}

.app-logo {
  width: 48px;
  height: 48px;
}

.capitalize {
  text-transform: capitalize;
}

.text-justify {
  text-align: justify;
}

@include mobile-only {
  div[class^='col-md-'] + div[class^='col-md-'] {
    margin-top: 1rem;
  }
}

.dropdown {
  &-toggle {
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    color: $text-color;
    display: inline-flex;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      background-color: transparent !important;
      color: $text-color !important;
      box-shadow: none !important;
    }
  }

  &.show {
    > .dropdown-toggle {
      outline: none;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &-menu {
    box-shadow: 0 8px 16px rgba(139, 139, 139, 0.12);
    border: none;
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  &-item {
    padding: 1rem;
    color: $text-dark !important;
    border-bottom: 1px solid $primary-1 !important;
    min-width: 216px;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      color: $text-dark;
      background-color: rgba($color: $primary-1, $alpha: 0.25) !important;
    }

    @include desktop {
      padding: 1.5rem;
    }
  }
}

.empty {
  &-state {
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include desktop {
      min-height: 500px;
    }
  }

  &-image {
    width: 200px;
    height: 200px;
    background-color: $primary-1;
    border-radius: 50%;
    padding: 3rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.link {
  color: blue;
}

// ANIMATIONS
.anim {
  &-zoom {
    animation: zoom-zero 1000ms linear;
    &-invert {
      animation: zoom-zero-invert 1000ms linear;
    }
  }
}

@keyframes zoom-zero {
  0% {
    opacity: 0.5;
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-20deg);
    opacity: 0.7;
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom-zero-invert {
  0% {
    opacity: 0.5;
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(15deg);
    opacity: 0.7;
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
  }
}

.primary {
  color: $primary;
}

.pointer {
  cursor: pointer;
}

[data-title] {
  position: relative;
  cursor: default;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  left: -10px;
  padding: 10px;
  background: $white;
  border: 1px solid $primary;
  color: $text-dark;
  font-size: 14px;
  white-space: nowrap;
}
