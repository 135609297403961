.c {
  &-sidebar {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 250px;
    height: 100vh;
    background-color: $primary-1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1.75rem;
    left: -80vw;
    transition: 200ms linear 50ms;

    &.show {
      left: 0;
    }

    @include desktop-lg {
      width: 265px;
      left: 0;
    }

    li {
      min-width: 48px;
    }

    &__logo {
      padding: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 32px;
        height: 32px;
      }
    }

    &__link {
      padding: 0.75rem 1.5rem;
      border-right: 5px solid transparent;
      width: 100%;
      transition: 200ms linear;
      font-size: 1rem;
      color: $text-dark;
      display: flex;
      align-items: center;
      font-weight: bold;

      &:hover,
      &.is-active {
        border-color: $primary;
        background-color: #b5ded0;
        color: $primary;
      }

      justify-content: flex-start;

      img {
        margin-right: 0.75rem;
        width: 24px;
        height: 24px;
      }

      @include desktop-lg {
        span {
          display: unset;
        }
      }
    }

    &__bottom {
      background-color: white;
      width: calc(100% - 3rem);
      margin-left: 1.5rem;
      border: 1px solid $primary-1;
      box-shadow: 0 8px 16px rgb(139 139 139 / 12%);
      border-radius: 8px;
    }

    &__nav {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
    }

    h4,
    h5 {
      font-weight: normal;
      word-break: break-all;
      line-height: 1;
      text-overflow: ellipsis;
    }

    &__business {
      padding: 1rem;
      border-bottom: 1px solid #d4e9e2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .col {
        padding: 0;
        flex-grow: 0;

        &:first-child {
          flex-grow: 1;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        width: 7rem;
      }

      &-rating {
        font-size: 60%;
      }

      &-rating-icon {
        width: 7px;
        height: 7px;
      }
    }

    &__user {
      display: flex;
      align-items: center;
      padding: 1rem 1rem 0.5rem;

      .flex {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      img {
        margin-right: 0.5rem;
        border-radius: 50%;
        background-color: $primary-1;
      }
    }

    &__overlay {
      position: fixed;
      z-index: 1;
      width: 100%;
      height: 100vh;
      background-color: rgb(33 33 33 / 50%);
      display: none;

      &.show {
        display: unset;
      }

      @include desktop-lg {
        display: none !important;
      }
    }

    &__avatar {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }
}
