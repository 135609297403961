html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
}

nav ul {
  list-style: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

label {
  font-weight: normal;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table td,
.table th {
  vertical-align: middle;
}

p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 2rem;
  margin: 0;
  font-weight: bold;
}

button {
  appearance: none;
  background: transparent;
  text-transform: none;
  border: none;
  cursor: pointer;
}

img,
video {
  max-width: 100%;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

blockquote {
  margin: 0;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator,
input[type=date]::-webkit-clear-button {
  appearance: none;
  margin: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

input[type=date] {
  padding-right: 0;
}

select {
  appearance: none;
  background: transparent;
  background-image: url("../../images/select-dropdown.svg");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

textarea {
  max-height: 100px;
  min-height: 80px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Graphik";
  background: #fff;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  color: #737a91;
}

html,
body {
  font-size: 14px;
}
@media screen and (min-width: 576px) {
  html,
  body {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  letter-spacing: 1.03px;
  color: #1e3932;
  font-family: "Graphik";
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

small,
.small {
  font-size: 85%;
}

:focus {
  outline: none;
  box-shadow: none !important;
}

b {
  font-weight: bolder;
}

strong {
  font-weight: bold;
}

@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: bolder;
  src: url("../../Fonts/Mabry/Mabry-Pro-Black.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: bolder;
  src: url("../../Fonts/Mabry/Mabry-Pro-Black-Italic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: bold;
  src: url("../../Fonts/Mabry/Mabry-Pro-Bold.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: bold;
  src: url("../../Fonts/Mabry/Mabry-Pro-Bold-Italic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: normal;
  src: url("../../Fonts/Mabry/Mabry-Pro-Medium.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: normal;
  src: url("../../Fonts/Mabry/Mabry-Pro-Medium-Italic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: lighter;
  src: url("../../Fonts/Mabry/Mabry-Pro-Light.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: lighter;
  src: url("../../Fonts/Mabry/Mabry-Pro-Light-Italic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: bolder;
  src: url("../../Fonts/Graphik/GraphikBlack.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: bolder;
  src: url("../../Fonts/Graphik/GraphikBlackItalic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: bold;
  src: url("../../Fonts/Graphik/GraphikMedium.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: bold;
  src: url("../../Fonts/Graphik/GraphikMediumItalic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  src: url("../../Fonts/Graphik/GraphikRegular.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: normal;
  src: url("../../Fonts/Graphik/GraphikRegularItalic.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: lighter;
  src: url("../../Fonts/Graphik/GraphikLight.otf") format("opentype");
  font-display: block;
}
@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: lighter;
  src: url("../../Fonts/Graphik/GraphikLightItalic.otf") format("opentype");
  font-display: block;
}
.c-input {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  padding: 0.688rem 1rem;
  width: 100%;
  transition: 200ms linear;
}
.c-input__box {
  position: relative;
  display: flex;
  align-items: center;
}
.c-input__placeholder {
  color: rgba(115, 122, 145, 0.5);
  position: absolute;
  pointer-events: none;
  font-size: 1rem;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease all;
}
.c-input__placeholder.textarea {
  top: 1.25rem;
}
.c-input__prefix {
  order: -1;
  border: 1px solid #d6d6d6;
  padding: 0.688rem 1rem;
  border-radius: 8px 0 0 8px;
}
.c-input__prefix--input {
  border-radius: 0 8px 8px 0;
  border-left: none;
}
.c-input__disabled {
  background-color: #f6f7f9;
}
.c-input.label, .c-input:focus {
  border-color: #008561;
}
.c-input.label ~ .c-input__placeholder, .c-input:focus ~ .c-input__placeholder {
  padding: 0 0.5rem;
  background-color: #fff;
  top: 0;
  bottom: unset;
  font-size: 10px;
  opacity: 1;
  color: #008561;
}
.c-input.label ~ .c-input__prefix, .c-input:focus ~ .c-input__prefix {
  color: #008561;
  border-color: #008561;
}
.c-input__error {
  border-color: #ff3b30;
}
.c-input__error.label, .c-input__error:focus {
  border-color: #ff3b30;
}
.c-input__error.label ~ .c-input__placeholder, .c-input__error:focus ~ .c-input__placeholder {
  color: #ff3b30;
}
.c-input__eye {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 30%;
  cursor: pointer;
  display: none;
}
.c-input__eye[type=password] {
  display: unset;
}
.c-search {
  flex-grow: 1;
  width: 100%;
  border: none;
  padding: 0;
  padding-left: 0.5rem;
  padding-top: 2px;
  color: #737a91;
}
.c-search__box {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  border-radius: 100px;
  border: 1px solid #d6d6d6;
  padding: 4px 1rem;
  font-size: 14px;
  height: 2rem;
}
.c-search__icon {
  flex: 0 0 16px;
}
.c-filter__box {
  border-radius: 100px;
  border: 1px solid #d6d6d6;
  padding: 4px 0;
  height: 2rem;
  background-color: #f6f7f9;
}
.c-filter__box ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.c-filter__box button.dropdown-toggle {
  font-size: 14px !important;
  line-height: 1.45rem;
  padding: 0 1rem;
}
.c-filter__box button.dropdown-toggle:active, .c-filter__box button.dropdown-toggle:focus, .c-filter__box button.dropdown-toggle:hover {
  background-color: transparent !important;
}
.c-filter__box button.dropdown-toggle::after {
  content: url("../../images/filter-icon.svg");
  border: none;
  margin-left: 0.5rem;
  transform: translateY(2px);
}
.c-filter__box .dropdown-item {
  padding: 1rem;
  min-width: 100px;
}
.c-filter__calendar {
  position: sticky;
  z-index: 1;
  border: 1px solid #d4e9e2;
}
.c-form__error {
  color: #fc0d00;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  position: relative;
  padding-left: 1rem;
  text-align: left;
}
.c-form__error::before {
  position: absolute;
  content: "";
  left: 0;
  width: 5px;
  height: 5px;
  background-color: red;
  top: 0.5rem;
  border-radius: 50%;
}
.c-btn {
  border-radius: 100px;
  background-color: #c4c4c4;
  padding: 1rem 3rem;
  transition: 100ms ease-in-out;
  line-height: 1rem;
  white-space: nowrap;
  align-items: center;
}
.c-btn:disabled {
  cursor: no-drop;
}
.c-btn__loader, .c-upload__text.loading .c-upload__icon {
  margin-left: -1rem;
  margin-right: 0.5rem;
  animation: rotate 3000ms linear infinite;
  height: 16px;
  width: 16px;
}
.c-btn__full-width {
  width: 100%;
}
.c-btn__primary {
  background-color: #008561;
  color: #fff;
}
.c-btn__primary:hover {
  background-color: #006c4e;
}
.c-btn__primary:disabled {
  background-color: #91c6b4;
}
.c-btn__transparent {
  background-color: transparent;
  color: #737a91;
}
.c-btn__transparent:hover {
  background-color: #e5f2ed;
}
.c-btn__transparent:disabled {
  background-color: #f6f7f9;
  color: #9ea3b3;
}
.c-btn__regular {
  background-color: #f6f7f9;
  color: #737a91;
}
.c-btn__regular:hover {
  background-color: #e5e6e8;
  color: #737a91;
}
.c-btn__inverted {
  background-color: #fff;
  border: #008561 1px solid;
  color: #008561;
  pointer-events: none;
}
.c-btn__expired {
  background-color: #fff;
  border: #ff3b30 1px solid;
  color: #ff3b30;
  pointer-events: none;
}
.c-btn__small {
  padding: 0.5rem 1.5rem;
}
.c-btn__small--no-loader {
  padding: 0.5rem 1rem;
}
.c-btn__small--text {
  font-size: 12px;
  line-height: 12px;
}
.c-btn__medium {
  padding: 1rem 1.5rem;
}
.c-btn__icon {
  padding: 0;
  width: 2rem;
  height: 2rem;
}
.c-btn + .c-btn {
  margin-left: 0.75rem;
}
.c-checkbox {
  transform: scale(1.5);
  margin-right: 6px;
  margin-left: 4px;
}
.c-checkbox__label {
  color: #737a91;
}
.c-checkbox__label, .c-checkbox__label * {
  text-transform: initial;
  font-size: 1rem;
}
.c-switch {
  position: relative;
  display: inline-block;
  height: 34px;
}
.c-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.c-switch .c-checkbox__label {
  margin-left: 65px;
  line-height: 34px;
  font-weight: bold;
  cursor: pointer;
  color: #1e3932;
}
.c-slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  width: 60px;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.c-slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .c-slider {
  background-color: #008561;
}
input:focus + .c-slider {
  box-shadow: 0 0 1px #d4e9e2;
}
input:checked + .c-slider::before {
  transform: translateX(26px);
}
.c-upload {
  position: absolute !important;
  width: 100%;
  height: 100% !important;
  min-width: unset !important;
  top: 0;
  opacity: 0;
  z-index: 2;
}
.c-upload__container {
  position: relative;
  padding-bottom: 1rem;
  top: 0;
}
.c-upload__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f7f9;
  border: 1px dashed #d4e9e2;
  border-radius: 8px;
  padding: 1rem;
  padding-bottom: 0;
}
.c-upload__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.c-upload__image--wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;
  height: 96px;
  width: 100%;
  position: relative;
}
.c-upload__image--wrapper.default {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-upload__image--wrapper.default img {
  height: 32px;
  width: 32px;
}
.c-upload__image--close {
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0.75rem;
  top: 0.75rem;
  z-index: 2;
  cursor: pointer;
  transition: 200ms linear;
}
.c-upload__image--close:hover {
  background-color: #f6f7f9;
}
.c-upload__text {
  text-align: center;
  margin: 1.5rem 0;
  max-width: 180px;
}
.c-upload__text.loading .c-upload__icon {
  margin: 0;
}
.c-upload__text.loading .c-upload__description {
  opacity: 0.5;
}
.c-upload__icon {
  width: 24px;
  height: 24px;
}
.c-upload__description {
  margin-bottom: 0;
  position: relative;
  transition: 200ms linear;
}
.c-upload__description img {
  display: none;
  position: absolute;
  margin: auto;
}
.c-upload__description span:first-child {
  color: #1e3932;
}

.form-group__half {
  flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .form-group__half {
    flex-basis: 49%;
    max-width: 49%;
  }
}
.form-meta {
  color: #1f3a33;
}
.form-row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 1rem;
  padding-left: 1rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-card {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 1rem;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .c-card {
    width: unset;
    box-shadow: 0 0 16px rgba(107, 107, 107, 0.12);
  }
}
.c-card__page {
  background: #fff;
  border: 1px solid #d4e9e2;
  box-shadow: 0 4px 16px rgba(107, 107, 107, 0.12);
  border-radius: 8px;
}
.c-card__page--header, .c-card__page--content {
  padding: 1.5rem;
  border-bottom: 1px solid #d4e9e2;
}
.c-card__page--header h5, .c-card__page--content h5 {
  letter-spacing: unset;
  font-family: Graphik;
}

.c-table {
  table-layout: auto;
  width: 100%;
  min-width: 600px;
}
.c-table__box {
  overflow-x: auto;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.c-table__dashboard {
  min-width: 0;
  width: 100%;
}
.c-table__dashboard-box {
  min-height: 0;
  border: none;
}
.c-table__header {
  font-size: 12px;
  line-height: 12px;
  background-color: #f6f7f9;
  padding: 14px;
}
.c-table__header:first-child {
  padding-left: 1.25rem;
}
.c-table__header:last-child {
  padding-right: 1.25rem;
}
.c-table__header::first-letter {
  text-transform: uppercase;
}
.c-table__row {
  border-bottom: 1px solid #d4e9e2;
}
.c-table__clickable-row:hover {
  background-color: #f6f7f9;
  cursor: pointer;
}
.c-table__data {
  color: #1e3932;
  padding: 1.5rem 14px;
}
.c-table__data:first-child {
  padding-left: 1rem;
}
.c-table__data:last-child {
  padding-right: 1.25rem;
}
.c-table__data.actions {
  display: flex;
  justify-content: flex-end;
}
.c-table__dashboard-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.5rem 0 0;
}
.c-table__title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem 1.25rem;
}
.c-table__title-no-padding {
  padding: 0;
}
.c-table__title > p {
  margin-bottom: 1rem;
}
@media screen and (min-width: 576px) {
  .c-table__title {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .c-table__title > p {
    margin-bottom: 0;
  }
}
.c-table__filters {
  display: flex;
  width: 100%;
}
.c-table__filters > div:first-child {
  margin-right: 1.5rem;
  flex-grow: 1;
}
.c-table__filters > div:only-child {
  margin-right: 0;
}
@media screen and (min-width: 576px) {
  .c-table__filters {
    width: auto;
  }
}
.c-table__filter-results {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 1.25rem;
  padding-bottom: 0.5rem;
}
.c-table__wrapper {
  min-height: 188px;
}
.c-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-pagination__box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.c-pagination__dashboard-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  padding-right: 1rem;
  justify-content: flex-end;
}
.c-pagination__pages button {
  padding: 0.5rem 1rem;
  background-color: #f6f7f9;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  color: #1e3932;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}
.c-pagination__pages button:active, .c-pagination__pages button:focus, .c-pagination__pages button:hover {
  background-color: transparent !important;
}
.c-pagination__pages button::after {
  content: url("../../images/filter-icon.svg");
  border: none;
  margin-left: 0.5rem;
  transform: translateY(-1px);
  width: 12px;
  height: 12px;
}
.c-pagination__pages .dropdown-item {
  padding: 1rem;
}
.c-pagination__meta {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 12px;
  color: #1e3932;
}
.c-pagination-item {
  margin-right: 0.5rem;
}
.c-pagination-item:last-child {
  margin-right: 0;
}
.c-pagination-link {
  font-size: 12px;
  padding: 0.5rem 1rem;
  background-color: #f6f7f9;
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  line-height: 0;
}
.c-pagination-link img.inverse {
  transform: rotate(180deg);
}
.c-pagination-link.disabled img {
  opacity: 0.5;
}
.c-dashboard-table__header {
  color: #737a91;
  font-size: 12px;
  padding: 22px 1rem 10px;
  border-bottom: 1px solid #D4E9E2;
}
.c-dashboard-table__data {
  padding: 1rem;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding-left: 0;
}
.breadcrumb-item.active {
  color: #008561;
  font-weight: bold;
}
.breadcrumb-item.active::before {
  color: #737a91;
  font-weight: normal;
}

.alert__icon {
  margin-right: 10px;
}
.alert__text {
  vertical-align: middle;
}
.alert__link {
  text-decoration: underline;
}
.alert__link:hover {
  text-decoration: underline;
}

.c-modal__footer {
  border-top: none;
}

.modal-header {
  border-bottom: none;
  padding: 12px 16px 0;
}
.modal-header > .c-modal__title {
  padding-bottom: 0 !important;
  color: #1e3932;
  letter-spacing: 0;
}

.modal-body {
  padding-top: 0;
  padding-bottom: 5px;
}

.p-3 {
  padding-left: 0 !important;
  padding-bottom: 1.8rem !important;
  padding-top: 0.3rem !important;
  color: #737a91;
}

.modal-content {
  border: 1px solid #d4e9e2;
  border-radius: 16px;
  padding: 0.5rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 576px) {
  .modal-content {
    width: 440px;
  }
}

.c-auth__gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
  min-height: 100vh;
}
@media screen and (min-width: 576px) {
  .c-auth__gradient {
    background-color: #d4e9e2;
  }
}
.c-auth__forgot {
  text-align: center;
  margin-top: 1rem;
  order: 2;
}
@media screen and (min-width: 576px) {
  .c-auth__forgot {
    text-align: left;
    margin-top: 0;
    order: unset;
  }
}
.c-auth__content {
  text-align: center;
}
@media screen and (min-width: 576px) {
  .c-auth__content {
    width: 360px;
  }
}

.color-gray {
  color: #737a91;
}
.color-primary {
  color: #008561;
}
.color-error {
  color: #ff3b30;
}
.color-amber {
  color: #d99617;
}

.react-calendar {
  width: 224px;
  max-width: 100%;
  background: #fff;
  border: none;
  line-height: 2.125em;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 0.5em;
  font-weight: bold;
}
.react-calendar__navigation__label {
  flex-grow: 3;
  flex-direction: column;
  margin: 6px;
}
.react-calendar__navigation__label__labelText {
  line-height: 3rem;
  font-weight: bold;
  font-style: normal;
  color: #1e3932;
}
.react-calendar__navigation__arrow {
  flex-grow: 0.2;
  align-self: flex-end;
}
.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next-button {
  font-size: 2rem;
  order: 2;
}
.react-calendar__navigation__prev-button {
  font-size: 2rem;
  order: 1;
}
.react-calendar__year-view__months, .react-calendar__decade-view__years__year {
  font-size: 0.9em;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.75em;
  color: #737A91;
  line-height: 3em;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration-line: none;
}
.react-calendar__month-view__days {
  line-height: 2em;
  font-weight: normal;
  font-size: 0.75em;
  text-align: center;
  color: #1e3932 !important;
}
.react-calendar__month-view__days__day:enabled:hover, .react-calendar__month-view__days__day:enabled:focus {
  border-radius: 50%;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #737a91 !important;
}
.react-calendar__month-view__days__day--neighboringMonth:enabled:hover, .react-calendar__month-view__days__day--neighboringMonth:enabled:focus {
  color: #1e3932 !important;
}
.react-calendar__tile {
  line-height: 2rem;
  color: #1e3932;
}
.react-calendar__tile--range {
  box-shadow: 0 0 #b5ded0;
  background-color: #d4e9e2 !important;
  color: #1e3932 !important;
}
.react-calendar__tile--range:enabled:hover, .react-calendar__tile--range:enabled:focus {
  border-radius: 0 !important;
}
.react-calendar__tile--rangeStart {
  border-radius: 50% 0 0 50%;
  color: #fff !important;
}
.react-calendar__tile--rangeStart:enabled:hover, .react-calendar__tile--rangeStart:enabled:focus {
  border-radius: 50% 0 0 50% !important;
}
.react-calendar__tile--rangeStart abbr {
  display: grid;
  background-color: #008561 !important;
  border-radius: 50% !important;
}
.react-calendar__tile--rangeEnd {
  border-radius: 0 50% 50% 0 !important;
  color: #fff !important;
}
.react-calendar__tile--rangeEnd:enabled:hover, .react-calendar__tile--rangeEnd:enabled:focus {
  border-radius: 0 50% 50% 0 !important;
}
.react-calendar__tile--rangeEnd abbr {
  display: grid;
  background-color: #008561 !important;
  border-radius: 50% !important;
}
.react-calendar__tile--rangeBothEnds {
  border-radius: 50% !important;
}
.react-calendar__tile--rangeBothEnds:enabled:hover, .react-calendar__tile--rangeBothEnds:enabled:focus {
  border-radius: 50% !important;
}
.react-calendar__tile--now {
  color: #008561;
  font-weight: 600;
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background-color: #d4e9e2 !important;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #d4e9e2;
}
.react-calendar__tile--hasActive {
  color: #008561;
}
.react-calendar__tile--active {
  background: #008561;
  color: #fff;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #008561;
  color: #fff;
}
.react-calendar__tile--selectRange .react-calendar__tile--hover {
  background-color: #008561;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
}

.c-chip {
  color: #fff;
  border: 1px solid #d4e9e2;
  box-shadow: 0 4px 16px rgba(107, 107, 107, 0.12);
  border-radius: 100px;
  background-color: #1e3932;
  padding: 0.2rem 0.4rem;
}

.c-sidebar {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 250px;
  height: 100vh;
  background-color: #d4e9e2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.75rem;
  left: -80vw;
  transition: 200ms linear 50ms;
}
.c-sidebar.show {
  left: 0;
}
@media screen and (min-width: 992px) {
  .c-sidebar {
    width: 265px;
    left: 0;
  }
}
.c-sidebar li {
  min-width: 48px;
}
.c-sidebar__logo {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-sidebar__logo img {
  width: 32px;
  height: 32px;
}
.c-sidebar__link {
  padding: 0.75rem 1.5rem;
  border-right: 5px solid transparent;
  width: 100%;
  transition: 200ms linear;
  font-size: 1rem;
  color: #1e3932;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: flex-start;
}
.c-sidebar__link:hover, .c-sidebar__link.is-active {
  border-color: #008561;
  background-color: #b5ded0;
  color: #008561;
}
.c-sidebar__link img {
  margin-right: 0.75rem;
  width: 24px;
  height: 24px;
}
@media screen and (min-width: 992px) {
  .c-sidebar__link span {
    display: unset;
  }
}
.c-sidebar__bottom {
  background-color: white;
  width: calc(100% - 3rem);
  margin-left: 1.5rem;
  border: 1px solid #d4e9e2;
  box-shadow: 0 8px 16px rgba(139, 139, 139, 0.12);
  border-radius: 8px;
}
.c-sidebar__nav {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.c-sidebar h4,
.c-sidebar h5 {
  font-weight: normal;
  word-break: break-all;
  line-height: 1;
  text-overflow: ellipsis;
}
.c-sidebar__business {
  padding: 1rem;
  border-bottom: 1px solid #d4e9e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-sidebar__business .col {
  padding: 0;
  flex-grow: 0;
}
.c-sidebar__business .col:first-child {
  flex-grow: 1;
}
.c-sidebar__business-row {
  display: flex;
  justify-content: space-between;
  width: 7rem;
}
.c-sidebar__business-rating {
  font-size: 60%;
}
.c-sidebar__business-rating-icon {
  width: 7px;
  height: 7px;
}
.c-sidebar__user {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.5rem;
}
.c-sidebar__user .flex {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.c-sidebar__user img {
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #d4e9e2;
}
.c-sidebar__overlay {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.5);
  display: none;
}
.c-sidebar__overlay.show {
  display: unset;
}
@media screen and (min-width: 992px) {
  .c-sidebar__overlay {
    display: none !important;
  }
}
.c-sidebar__avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.c-dashboard {
  padding-left: 0;
}
.c-dashboard__layout {
  padding-bottom: 2rem;
}
.c-dashboard__container {
  padding: 1rem 2rem;
}
@media screen and (min-width: 992px) {
  .c-dashboard {
    padding-left: 265px;
  }
}
.c-header {
  padding: 1rem 0 1.5rem;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .c-header {
    padding-top: 2rem;
  }
}
.c-header__content {
  flex: 1 0 70%;
}
.c-header__title {
  font-size: 1.5rem;
  font-weight: normal;
  font-family: Mabry;
  letter-spacing: 0;
  text-transform: capitalize;
}
.c-header__subtitle {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  color: black;
  font-family: "Graphik";
}
.c-header__hamburger {
  flex: 0 0 30px;
}
.c-header__hamburger button {
  height: 30px;
  width: 30px;
  background-color: #d4e9e2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 1rem;
}
.c-header__hamburger button img {
  width: 18px;
}
@media screen and (min-width: 992px) {
  .c-header__hamburger {
    display: none;
  }
}

.card-columns {
  column-count: 1;
}

.home-card {
  color: #1e3932;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(189, 189, 189, 0.12);
  border: 1px solid #d4e9e2;
  box-sizing: border-box;
  height: 100%;
}
.home-card.welcome-card-inner {
  margin: 0;
}
.home-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}
.home-text__title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 0.5rem;
}
.home-text__regular--title {
  font-size: 16px;
  margin-top: 0.5rem;
  color: #737a91;
}
.home-text__description {
  font-size: 12px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #1e3932;
  padding-top: 5px;
}
.card-text {
  color: #1e3932;
}
.card-body {
  padding: 1.5rem;
}

.home-card-time-frame {
  font-size: 12px;
  padding-top: 7px;
}

.dashboard-col {
  margin-right: 0;
}

.welcome-bg {
  background: #fbe2c5;
  border-radius: 8px;
  margin-bottom: 25px;
  padding: 1.5rem;
}

.btn-col {
  text-align: right;
}

.tab-grass-row {
  display: none;
}

.tablegrass {
  position: sticky;
  left: 1100px;
}

.chk-box-col {
  display: flex;
}
.chk-box-col img {
  flex: 0 0 24px;
  margin-right: 0.75rem;
}

.welcome-card-todo {
  margin-bottom: 1.5rem;
  align-items: center;
}
.welcome-card-todo:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .card-text {
    width: 100%;
  }
  .card-body {
    padding: 2rem;
  }
  .home-card .card-body {
    padding: 1.5rem 1rem;
  }
  .home-text {
    padding: 5.5rem 0;
  }
  .welcome-p-color {
    color: #737a91;
  }
  .welcome-block {
    column-count: 1;
  }
  .welcome-card-text {
    margin-left: 0;
    margin-right: 0;
  }
  .welcome-bg {
    padding: 2rem;
  }
  .tab-grass-row {
    height: 80px;
    margin-top: -80px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 3rem;
  }
  .card-columns {
    column-count: 2;
  }
}
.pull-right {
  float: right;
}

.dashboard-empty__box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.dashboard-empty__card {
  flex: 0 0 100%;
  padding: 1rem;
}
@media screen and (min-width: 768px) {
  .dashboard-empty__card {
    flex-basis: 50%;
  }
}
.dashboard-amount {
  text-align: right;
}

.dashboard-card__body {
  padding: 1.5rem 0;
}
.dashboard-card__title {
  padding: 0 1rem;
}

.c-table__row > td:last-child:not(.actions, .only) {
  text-align: right;
}

.top-service__row > td:last-child {
  text-align: left;
}

.bookings__img {
  height: 2rem;
}
.bookings__rating {
  width: 10rem;
}
.bookings__star {
  padding-right: 13px;
}
.bookings__cancellation-note-link {
  cursor: pointer;
}

.d__box {
  border-radius: 100px;
  border: 1px solid #d6d6d6;
  padding: 4px 0;
  height: 2rem;
  background-color: #008561;
}
.d__box button.dropdown-toggle {
  font-size: 14px !important;
  line-height: 1.45rem;
  color: #fff !important;
  padding: 0 1rem;
}
.d__box button.dropdown-toggle:active, .d__box button.dropdown-toggle:focus, .d__box button.dropdown-toggle:hover {
  background-color: transparent !important;
}
.d__box button.dropdown-toggle::after {
  display: none;
}
.d__box .dropdown-item {
  padding: 1rem;
  min-width: 100px;
}

.service__form {
  max-width: 776px;
  margin-bottom: 1rem;
}
.service__type {
  font-size: 16px;
}
.service__type-draft {
  color: #d99617;
}
.service__type-published {
  color: #008561;
}

.settings-tab__list {
  display: flex;
  overflow-x: auto;
  position: relative;
}
.settings-tab__list::after {
  height: 2px;
  content: "";
  background-color: #dfe2e6;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.settings-tab__item {
  margin-right: 3rem;
  position: relative;
}
.settings-tab__item:last-child {
  margin-right: 0;
}
.settings-tab__link {
  padding: 0.5rem 0 1rem;
  transition: 200ms linear;
  display: inline-block;
  text-transform: capitalize;
}
.settings-tab__link:hover, .settings-tab__link.active {
  color: #008561;
}
.settings-tab__link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background-color: #008561;
  transition: 10000ms linear;
  z-index: 1;
}
.settings-tab__content {
  padding-top: 1.5rem;
}

.business-profile__form {
  padding-bottom: 2rem;
}
.business-profile__form .c-input__box {
  margin-top: 1.5rem;
}
.business-profile__form .form-title {
  padding-right: 1rem;
}
.business-profile__form .form-title__wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: space-between;
}
.business-profile__hr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.business-profile__hr::after {
  height: 1px;
  width: 100%;
  background-color: #d4e9e2;
  content: "";
}
@media screen and (min-width: 992px) {
  .business-profile__hr {
    flex: 0 1;
    padding: 0;
  }
  .business-profile__hr::after {
    width: 1px;
    height: 100%;
  }
}
.business-profile__images {
  overflow-y: auto;
  max-height: 75vh;
}
.business-profile__images > div {
  margin-top: 1rem;
}
.business-profile__empty .card-body {
  height: unset;
}

.plan-card {
  color: #1e3932;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(189, 189, 189, 0.12);
  border: 1px solid #d4e9e2;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem;
}
.plan-card-features {
  margin-bottom: 0.7rem;
  align-items: center;
}
.plan-card-features:last-child {
  margin-bottom: 0;
}
.plan-selected {
  border: 3px solid #008561;
  box-sizing: border-box;
  box-shadow: 0 8px 24px #d4e9e2;
  border-radius: 8px;
}
.plan-description {
  color: #737a91;
  min-height: 60px;
}

.payment-card {
  height: 200px;
}
.payment-card__details {
  padding: 0.75em;
}
.payment-card__details-data {
  min-height: 30px;
}
.payment-card__details-name {
  position: absolute;
  top: 27px;
  color: #1e3932;
}
.payment-card__details-expiry-date {
  color: #1e3932;
  position: absolute;
  top: 115px;
}
.payment-card__expired {
  border: #ff3b30 0.1px solid;
}
.payment-card-hr {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 0.75rem;
}
.payment-card-hr__expired {
  color: #ff3b30;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 0.75rem;
}
.payment-card-button-set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #E5E5E5;
  min-height: 52px;
  padding: 0.75rem;
}
.payment-bank {
  height: 243px;
}

.analytics-card {
  height: 110px;
  width: 49%;
}
.analytics-text {
  padding: 0;
}
.analytics-title {
  color: #737a91;
  font-size: 12px;
}
.analytics-doughnut {
  width: 160px;
  height: 160px;
}
.analytics-appointment-chart {
  width: 100%;
  padding: 1rem;
}
.analytics-customer-rating {
  width: 1rem;
}

.profile__form-vr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.profile__form-vr::after {
  height: 1px;
  width: 100%;
  margin-top: -100px;
  margin-bottom: -100px;
  background-color: #d4e9e2;
  content: "";
}
@media screen and (min-width: 992px) {
  .profile__form-vr {
    flex: 0 1;
    padding: 0;
  }
  .profile__form-vr::after {
    width: 1px;
    height: 100%;
  }
}
.profile__form_slider {
  display: flex;
  flex-flow: column-reverse;
}

.revenue__modal-table {
  height: 270px;
}

.link-text {
  color: #008561;
}
.link-text:hover {
  text-decoration: underline;
  color: #00523c;
  transition: all 200ms linear;
}

.text-md {
  font-size: 20px;
}
.text-dark {
  color: #1e3932;
}

.app-logo {
  width: 48px;
  height: 48px;
}

.capitalize {
  text-transform: capitalize;
}

.text-justify {
  text-align: justify;
}

@media screen and (max-width: 575px) {
  div[class^=col-md-] + div[class^=col-md-] {
    margin-top: 1rem;
  }
}
.dropdown-toggle {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  color: #737a91;
  display: inline-flex;
}
.dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle:focus {
  outline: none;
  background-color: transparent !important;
  color: #737a91 !important;
  box-shadow: none !important;
}
.dropdown.show > .dropdown-toggle {
  outline: none;
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-menu {
  box-shadow: 0 8px 16px rgba(139, 139, 139, 0.12);
  border: none;
  padding: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}
.dropdown-item {
  padding: 1rem;
  color: #1e3932 !important;
  border-bottom: 1px solid #d4e9e2 !important;
  min-width: 216px;
}
.dropdown-item:last-child {
  border-bottom: none;
}
.dropdown-item:hover {
  color: #1e3932;
  background-color: rgba(212, 233, 226, 0.25) !important;
}
@media screen and (min-width: 768px) {
  .dropdown-item {
    padding: 1.5rem;
  }
}

.empty-state {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .empty-state {
    min-height: 500px;
  }
}
.empty-image {
  width: 200px;
  height: 200px;
  background-color: #d4e9e2;
  border-radius: 50%;
  padding: 3rem;
}
.empty-image img {
  width: 100%;
  height: 100%;
}
.empty-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.link {
  color: blue;
}

.anim-zoom {
  animation: zoom-zero 1000ms linear;
}
.anim-zoom-invert {
  animation: zoom-zero-invert 1000ms linear;
}

@keyframes zoom-zero {
  0% {
    opacity: 0.5;
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-20deg);
    opacity: 0.7;
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-zero-invert {
  0% {
    opacity: 0.5;
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(15deg);
    opacity: 0.7;
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
  }
}
.primary {
  color: #008561;
}

.pointer {
  cursor: pointer;
}

[data-title] {
  position: relative;
  cursor: default;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: 30px;
  left: -10px;
  padding: 10px;
  background: #fff;
  border: 1px solid #008561;
  color: #1e3932;
  font-size: 14px;
  white-space: nowrap;
}

