.c {
  &-chip {
    color: $white;
    border: 1px solid $primary-1;
    box-shadow: 0 4px 16px $box-shadow;
    border-radius: 100px;
    background-color: $text-dark;
    padding: 0.2rem 0.4rem;
  }
}
