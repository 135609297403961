.bookings {
  &__img {
    height: 2rem;
  }

  &__rating {
    width: 10rem;
  }

  &__star {
    padding-right: 13px;
  }

  &__cancellation-note-link {
    cursor: pointer;
  }
}
