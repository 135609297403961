.c {
  &-dashboard {
    padding-left: 0;

    &__layout {
      padding-bottom: 2rem;
    }

    &__container {
      padding: 1rem 2rem;
    }

    @include desktop-lg {
      padding-left: 265px;
    }
  }

  &-header {
    padding: 1rem 0 1.5rem;
    display: flex;
    align-items: center;

    @include tablet {
      padding-top: 2rem;
    }

    &__content {
      flex: 1 0 70%;
    }

    &__title {
      font-size: 1.5rem;
      font-weight: normal;
      font-family: Mabry;
      letter-spacing: 0;
      text-transform: capitalize;
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      color: black;
      font-family: $font-family;
    }

    &__hamburger {
      flex: 0 0 30px;

      button {
        height: 30px;
        width: 30px;
        background-color: $primary-1;

        @include flex-center;

        border-radius: 50%;
        margin-left: 1rem;

        img {
          width: 18px;
        }
      }

      @include desktop-lg {
        display: none;
      }
    }
  }
}
