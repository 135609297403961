.settings {
  &-tab {
    &__list {
      display: flex;
      overflow-x: auto;
      position: relative;

      &::after {
        height: 2px;
        content: '';
        background-color: $primary-2;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    &__item {
      margin-right: 3rem;
      position: relative;

      &:last-child {
        margin-right: 0;
      }
    }

    &__link {
      padding: 0.5rem 0 1rem;
      transition: 200ms linear;
      display: inline-block;
      text-transform: capitalize;

      &:hover,
      &.active {
        color: $primary;
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          border-radius: 10px;
          background-color: $primary;
          transition: 10000ms linear;
          z-index: 1;
        }
      }
    }

    &__content {
      padding-top: 1.5rem;
    }
  }
}
