.business-profile {
  &__form {
    padding-bottom: 2rem;

    .c-input__box {
      margin-top: 1.5rem;
    }

    .form-title {
      padding-right: 1rem;

      &__wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        justify-content: space-between;
      }
    }
  }

  &__hr {
    @include flex-center;

    flex: 1 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &::after {
      height: 1px;
      width: 100%;
      background-color: $primary-1;
      content: '';
    }

    @include desktop-lg {
      flex: 0 1;
      padding: 0;

      &::after {
        width: 1px;
        height: 100%;
      }
    }
  }

  &__images {
    overflow-y: auto;
    max-height: 75vh;

    > div {
      margin-top: 1rem;
    }
  }

  &__empty {
    .card-body {
      height: unset;
    }
  }
}
