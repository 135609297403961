.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding-left: 0;

  &-item {
    &.active {
      color: $primary;
      font-weight: bold;

      &::before {
        color: $text-color;
        font-weight: normal;
      }
    }
  }
}
