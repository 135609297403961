@mixin mobile-only {
  @media #{$mobile} {
    @content;
  }
}

@mixin tablet {
  @media #{$tablet} {
    @content;
  }
}

@mixin desktop {
  @media #{$desktop} {
    @content;
  }
}

@mixin desktop-lg {
  @media #{$desktop-lg} {
    @content;
  }
}

@mixin desktop-xl {
  @media #{$desktop-xl} {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
