.c {
  &-card {
    background-color: $white;
    padding: 2.5rem;
    border-radius: 1rem;
    width: 100%;

    @include tablet {
      width: unset;
      box-shadow: 0 0 16px $box-shadow;
    }

    &__page {
      background: $white;
      border: 1px solid $primary-1;
      box-shadow: 0 4px 16px $box-shadow;
      border-radius: 8px;

      &--header,
      &--content {
        padding: 1.5rem;
        border-bottom: 1px solid $primary-1;

        h5 {
          letter-spacing: unset;
          font-family: Graphik;
        }
      }
    }
  }
}
