html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
}

nav ul {
  list-style: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

label {
  font-weight: normal;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table td,
.table th {
  vertical-align: middle;
}

p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 2rem;
  margin: 0;
  font-weight: bold;
}

button {
  appearance: none;
  background: transparent;
  text-transform: none;
  border: none;
  cursor: pointer;
}

img,
video {
  max-width: 100%;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

blockquote {
  margin: 0;
}

button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button,
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-clear-button {
  appearance: none;
  margin: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

input[type='number'] {
  appearance: textfield;
}

input[type='date'] {
  padding-right: 0;
}

select {
  appearance: none;
  background: transparent;
  background-image: url('../../images/select-dropdown.svg');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

textarea {
  max-height: 100px;
  min-height: 80px;
}
