.color {
  &-gray {
    color: $text-color;
  }

  &-primary {
    color: $primary;
  }

  &-error {
    color: $error;
  }

  &-amber {
    color: $amber;
  }
}
