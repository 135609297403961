.c {
  &-auth {
    &__gradient {
      @include flex-center;

      padding-top: 2rem;
      padding-bottom: 2rem;
      flex-direction: column;
      min-height: 100vh;

      @include tablet {
        background-color: $primary-1;
      }
    }

    &__forgot {
      text-align: center;
      margin-top: 1rem;
      order: 2;

      @include tablet {
        text-align: left;
        margin-top: 0;
        order: unset;
      }
    }

    &__content {
      text-align: center;

      @include tablet {
        width: 360px;
      }
    }
  }
}
