.plan {
  &-card {
    color: $text-dark;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 16px rgb(189 189 189 / 12%);
    border: 1px solid $primary-1;
    box-sizing: border-box;
    height: 100%;
    padding: 0.5rem;
  }

  &-card-features {
    margin-bottom: 0.7rem;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-selected {
    border: 3px solid $primary;
    box-sizing: border-box;
    box-shadow: 0 8px 24px $primary-1;
    border-radius: 8px;
  }

  &-description {
    color: $text-color;
    min-height: 60px;
  }
}

.payment {
  &-card {
    height: 200px;

    &__details {
      padding: 0.75em;

      &-data {
        min-height: 30px;
      }

      &-name {
        position: absolute;
        top: 27px;
        color: $text-dark;
      }

      &-expiry-date {
        color: $text-dark;
        position: absolute;
        top: 115px;
      }
    }

    &__expired {
      border: $error 0.1px solid;
    }

    &-hr {
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: 0.75rem;

      &__expired {
        color: $error;
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 0.75rem;
      }
    }

    &-button-set {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 1px solid #E5E5E5;
      min-height: 52px;
      padding: 0.75rem;
    }
  }

  &-bank {
    height: 243px;
  }
}
