// colors
$white: #fff;
$black: #000;
$amber: #d99617;
$primary: #008561;
$primary-1: #d4e9e2;
$primary-2: #dfe2e6;
$text-color: #737a91;
$text-dark: #1e3932;
$border-color: #d6d6d6;
$error: #ff3b30;
$silver: #f6f7f9;
$orange: #fbe2c5;
$box-shadow: rgb(107 107 107 / 12%);

// Bootstrap Colors
$theme-colors: (
  'primary': #008561,
  'accent': #ff6f00,
  'danger': #ff3b30,
  'light': #f1f1f1
);

// fonts
$font-family: 'Graphik';
$base-font-size: 100%;

// Screen Sizes for responsiveness
$mobile: 'screen and (max-width: 575px)';
$tablet: 'screen and (min-width: 576px)';
$desktop: 'screen and (min-width: 768px)';
$desktop-lg: 'screen and (min-width: 992px)';
$desktop-xl: 'screen and (min-width: 1200px)';
