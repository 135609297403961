.profile {
  &__form {
    &-vr {
      @include flex-center;

      flex: 1 0;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      &::after {
        height: 1px;
        width: 100%;
        margin-top: -100px;
        margin-bottom: -100px;
        background-color: $primary-1;
        content: '';
      }

      @include desktop-lg {
        flex: 0 1;
        padding: 0;

        &::after {
          width: 1px;
          height: 100%;
        }
      }
    }

    &_slider {
      display: flex;
      flex-flow: column-reverse;
    }
  }
}
