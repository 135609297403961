.alert {
  &__icon {
    margin-right: 10px;
  }

  &__text {
    vertical-align: middle;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
