.c {
  &-table {
    table-layout: auto;
    width: 100%;
    min-width: 600px;

    &__box {
      overflow-x: auto;
      border: 1px solid $border-color;
      border-radius: 8px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__dashboard {
      min-width: 0;
      width: 100%;
    }

    &__dashboard-box {
      min-height: 0;
      border: none;
    }

    &__header {
      font-size: 12px;
      line-height: 12px;
      background-color: $silver;
      padding: 14px;

      &:first-child {
        padding-left: 1.25rem;
      }

      &:last-child {
        padding-right: 1.25rem;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__row {
      border-bottom: 1px solid $primary-1;
    }

    &__clickable-row {
      &:hover {
        background-color: $silver;
        cursor: pointer;
      }
    }

    &__data {
      color: $text-dark;
      padding: 1.5rem 14px;

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 1.25rem;
      }

      &.actions {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__dashboard-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: .5rem 0 0;
    }

    &__title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 1.5rem 1.25rem;

      &-no-padding {
        padding: 0;
      }

      > p {
        margin-bottom: 1rem;
      }

      @include tablet {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        > p {
          margin-bottom: 0;
        }
      }
    }

    &__filters {
      display: flex;
      width: 100%;

      > div {
        &:first-child {
          margin-right: 1.5rem;
          flex-grow: 1;
        }

        &:only-child {
          margin-right: 0;
        }
      }

      @include tablet {
        width: auto;
      }
    }

    &__filter-results {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 1.25rem;
      padding-bottom: 0.5rem;
    }

    &__wrapper {
      min-height: 188px;
    }
  }

  &-pagination {
    @include flex-center;

    &__box {
      @include flex-center;

      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &__dashboard-box {
      @include flex-center;

      padding-top: 1.5rem;
      padding-right: 1rem;
      justify-content: flex-end;
    }

    &__pages {
      button {
        padding: 0.5rem 1rem;
        background-color: $silver;
        border-radius: 6px;
        border: 1px solid $border-color;
        color: $text-dark;
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;

        &:active,
        &:focus,
        &:hover {
          background-color: transparent !important;
        }

        &::after {
          content: url('../../images/filter-icon.svg');
          border: none;
          margin-left: 0.5rem;
          transform: translateY(-1px);
          width: 12px;
          height: 12px;
        }
      }

      .dropdown-item {
        padding: 1rem;
      }
    }

    &__meta {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 12px;
      color: $text-dark;
    }

    &-item {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      font-size: 12px;
      padding: 0.5rem 1rem;
      background-color: $silver;
      border-radius: 6px;
      border: 1px solid $border-color;
      line-height: 0;

      img.inverse {
        transform: rotate(180deg);
      }

      &.disabled {
        img {
          opacity: 0.5;
        }
      }
    }
  }

  &-dashboard-table__header {
    color: $text-color;
    font-size: 12px;
    padding: 22px 1rem 10px;
    border-bottom: 1px solid #D4E9E2;
  }

  &-dashboard-table__data {
    padding: 1rem;
  }
}
