.card-columns {
  column-count: 1;
}

.home {
  &-card {
    color: $text-dark;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 16px rgb(189 189 189 / 12%);
    border: 1px solid $primary-1;
    box-sizing: border-box;
    height: 100%;

    &.welcome-card-inner {
      margin: 0;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 0;

    &__title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 0.5rem;
    }

    &__regular--title {
      font-size: 16px;
      margin-top: 0.5rem;
      color: $text-color;
    }

    &__description {
      font-size: 12px;
    }
  }
}

.card {
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: $text-dark;
    padding-top: 5px;
  }

  &-text {
    color: $text-dark;
  }

  &-body {
    padding: 1.5rem;
  }
}

.home-card-time-frame {
  font-size: 12px;
  padding-top: 7px;
}

.dashboard-col {
  margin-right: 0;
}

.welcome-bg {
  background: $orange;
  border-radius: 8px;
  margin-bottom: 25px;
  padding: 1.5rem;
}

.btn-col {
  text-align: right;
}

.tab-grass-row {
  display: none;
}

.tablegrass {
  position: sticky;
  left: 1100px;
}

.chk-box-col {
  display: flex;

  img {
    flex: 0 0 24px;
    margin-right: 0.75rem;
  }
}

.welcome-card-todo {
  margin-bottom: 1.5rem;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

@include desktop-lg {
  .card {
    &-text {
      width: 100%;
    }

    &-body {
      padding: 2rem;
    }
  }

  .home {
    &-card {
      .card-body {
        padding: 1.5rem 1rem;
      }
    }

    &-text {
      padding: 5.5rem 0;
    }
  }

  .welcome {
    &-p-color {
      color: $text-color;
    }

    &-block {
      column-count: 1;
    }

    &-card-text {
      margin-left: 0;
      margin-right: 0;
    }

    &-bg {
      padding: 2rem;
    }
  }

  .tab-grass-row {
    height: 80px;
    margin-top: -80px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 3rem;
  }

  .card-columns {
    column-count: 2;
  }
}

.pull-right {
  float: right;
}

.dashboard {
  &-empty {
    &__box {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
    }

    &__card {
      flex: 0 0 100%;
      padding: 1rem;

      @include desktop {
        flex-basis: 50%;
      }
    }
  }

  &-amount {
    text-align: right;
  }
}

.dashboard-card {
  &__body {
    padding: 1.5rem 0;
  }

  &__title {
  padding: 0 1rem;
  }
}

.c-table__row > td:last-child:not(.actions, .only) {
  text-align: right;
}

.top-service__row > td:last-child {
  text-align: left;
}
