// MABRY FONT
@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: bolder;
  src: url('../../Fonts/Mabry/Mabry-Pro-Black.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: bolder;
  src: url('../../Fonts/Mabry/Mabry-Pro-Black-Italic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: bold;
  src: url('../../Fonts/Mabry/Mabry-Pro-Bold.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: bold;
  src: url('../../Fonts/Mabry/Mabry-Pro-Bold-Italic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: normal;
  src: url('../../Fonts/Mabry/Mabry-Pro-Medium.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: normal;
  src: url('../../Fonts/Mabry/Mabry-Pro-Medium-Italic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: normal;
  font-weight: lighter;
  src: url('../../Fonts/Mabry/Mabry-Pro-Light.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Mabry;
  font-style: italic;
  font-weight: lighter;
  src: url('../../Fonts/Mabry/Mabry-Pro-Light-Italic.otf') format('opentype');
  font-display: block;
}

// Graphik
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: bolder;
  src: url('../../Fonts/Graphik/GraphikBlack.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: bolder;
  src: url('../../Fonts/Graphik/GraphikBlackItalic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: bold;
  src: url('../../Fonts/Graphik/GraphikMedium.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: bold;
  src: url('../../Fonts/Graphik/GraphikMediumItalic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  src: url('../../Fonts/Graphik/GraphikRegular.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: normal;
  src: url('../../Fonts/Graphik/GraphikRegularItalic.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: lighter;
  src: url('../../Fonts/Graphik/GraphikLight.otf') format('opentype');
  font-display: block;
}

@font-face {
  font-family: Graphik;
  font-style: italic;
  font-weight: lighter;
  src: url('../../Fonts/Graphik/GraphikLightItalic.otf') format('opentype');
  font-display: block;
}
