.react-calendar {
  width: 224px;
  max-width: 100%;
  background: $white;
  border: none;
  line-height: 2.125em;

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: .5em;
    font-weight: bold;

    &__label {
      flex-grow: 3;
      flex-direction: column;
      margin: 6px;

      &__labelText {
        line-height: 3rem;
        font-weight: bold;
        font-style: normal;
        color: $text-dark;
      }
    }

    &__arrow {
      flex-grow: 0.2;
      align-self: flex-end;
    }

    &__next2-button,
    &__prev2-button {
      display: none;
    }

    &__next-button {
      font-size: 2rem;
      order: 2;
    }

    &__prev-button {
      font-size: 2rem;
      order: 1;
    }
  }

  &__year-view__months,
  &__decade-view__years__year {
    font-size: 0.9em;
  }

  &__month-view__weekdays__weekday {
    text-align: center;
    text-transform:capitalize;
    font-weight: 400;
    font-size: 0.75em;
    color: #737A91;
    line-height: 3em;
  }

  &__month-view__weekdays__weekday abbr {
    text-decoration-line: none;
  }

  &__month-view__days {
    line-height: 2em;
    font-weight: normal;
    font-size: 0.75em;
    text-align: center;
    color: $text-dark !important;

    &__day {
      &:enabled:hover,
      &:enabled:focus {
        border-radius: 50%;

      }
    }

    &__day--neighboringMonth {
      color: $text-color !important;

      &:enabled:hover,
      &:enabled:focus {
        color: $text-dark !important;
      }
    }
  }

  &__tile  {
    line-height: 2rem;
    color: $text-dark;

    &--range {
      box-shadow: 0 0 #b5ded0;
      background-color: $primary-1 !important;
      color: $text-dark !important;

      &:enabled:hover,
      &:enabled:focus {
        border-radius: 0 !important;
      }
    }

    &--rangeStart {
      border-radius: 50% 0 0 50%;
      color: $white !important;

      &:enabled:hover,
      &:enabled:focus {
        border-radius: 50% 0 0 50% !important;
      }

      abbr {
        display: grid;
        background-color: $primary !important;
        border-radius: 50% !important;
      }
    }

    &--rangeEnd {
      border-radius: 0 50% 50% 0 !important;
      color: $white !important;

      &:enabled:hover,
      &:enabled:focus {
        border-radius: 0 50% 50% 0 !important;
      }

      abbr {
        display: grid;
        background-color: $primary !important;
        border-radius: 50% !important;
      }
    }

    &--rangeBothEnds {
      border-radius: 50% !important;

      &:enabled:hover, &:enabled:focus {
        border-radius: 50% !important;
      }
    }

    &--now {
      color: $primary;
      font-weight: 600;

      &:enabled:hover,
      &:enabled:focus {
        background-color: $primary-1 !important;
      }
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-1;
    }

    &--hasActive {
      color: $primary;
    }

    &--active {
      background: $primary;
      color: $white;

      &:enabled:hover,
      &:enabled:focus {
        background: $primary;
        color: $white;
      }
    }

    &--selectRange .react-calendar__tile--hover {
      background-color: $primary;
      height: 2rem;
      line-height: 2rem;
      border-radius: 50%;
    }
  }
};
