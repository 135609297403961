.c {
  &-input {
    border-radius: 8px;
    border: 1px solid $border-color;
    padding: 0.688rem 1rem;
    width: 100%;
    transition: 200ms linear;

    &__box {
      position: relative;
      display: flex;
      align-items: center;
    }

    &__placeholder {
      color: rgb(115 122 145 / 50%);
      position: absolute;
      pointer-events: none;
      font-size: 1rem;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.2s ease all;

      &.textarea {
        top: 1.25rem;
      }
    }

    &__prefix {
      order: -1;
      border: 1px solid $border-color;
      padding: 0.688rem 1rem;
      border-radius: 8px 0 0 8px;
    }

    &__prefix--input {
      border-radius: 0 8px 8px 0;
      border-left: none;
    }

    &__disabled {
      background-color: darken($color: $silver, $amount: 0);
    }

    &.label,
    &:focus {
      border-color: $primary;

      ~ .c-input__placeholder {
        padding: 0 0.5rem;
        background-color: $white;
        top: 0;
        bottom: unset;
        font-size: 10px;
        opacity: 1;
        color: $primary;
      }

      ~ .c-input__prefix {
        color: $primary;
        border-color: $primary;
      }
    }

    &__error {
      border-color: $error;

      &.label,
      &:focus {
        border-color: $error;

        ~ .c-input__placeholder {
          color: $error;
        }
      }
    }

    &__eye {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 10px;
      top: 30%;
      cursor: pointer;
      display: none;

      &[type='password'] {
        display: unset;
      }
    }
  }

  &-search {
    &__box {
      @include flex-center;

      justify-content: flex-start;
      border-radius: 100px;
      border: 1px solid $border-color;
      padding: 4px 1rem;
      font-size: 14px;
      height: 2rem;
    }

    &__icon {
      flex: 0 0 16px;
    }

    flex-grow: 1;
    width: 100%;
    border: none;
    padding: 0;
    padding-left: 0.5rem;
    padding-top: 2px;
    color: $text-color;
  }

  &-filter {
    &__box {
      border-radius: 100px;
      border: 1px solid $border-color;
      padding: 4px 0;
      height: 2rem;
      background-color: $silver;

      ul {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      button.dropdown-toggle {
        font-size: 14px !important;
        line-height: 1.45rem;
        padding: 0 1rem;

        &:active,
        &:focus,
        &:hover {
          background-color: transparent !important;
        }

        &::after {
          content: url('../../images/filter-icon.svg');
          border: none;
          margin-left: 0.5rem;
          transform: translateY(2px);
        }
      }

      .dropdown-item {
        padding: 1rem;
        min-width: 100px;
      }
    }

    &__calendar {
      position: sticky;
      z-index: 1;
      border: 1px solid $primary-1;
    }
  }

  &-form {
    &__error {
      color: darken($color: $error, $amount: 10);
      margin-top: 0.25rem;
      font-size: 0.875rem;
      position: relative;
      padding-left: 1rem;
      text-align: left;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 5px;
        height: 5px;
        background-color: red;
        top: 0.5rem;
        border-radius: 50%;
      }
    }
  }

  &-btn {
    border-radius: 100px;
    background-color: #c4c4c4;
    padding: 1rem 3rem;
    transition: 100ms ease-in-out;
    line-height: 1rem;
    white-space: nowrap;
    align-items: center;

    &:disabled {
      cursor: no-drop;
    }

    &__loader {
      margin-left: -1rem;
      margin-right: 0.5rem;
      animation: rotate 3000ms linear infinite;
      height: 16px;
      width: 16px;
    }

    &__full-width {
      width: 100%;
    }

    &__primary {
      background-color: $primary;
      color: $white;

      &:hover {
        background-color: darken($color: $primary, $amount: 5);
      }

      &:disabled {
        background-color: darken($color: $primary-1, $amount: 20);
      }
    }

    &__transparent {
      background-color: transparent;
      color: $text-color;

      &:hover {
        background-color: lighten($color: $primary-1, $amount: 5);
      }

      &:disabled {
        background-color: $silver;
        color: lighten($color: $text-color, $amount: 15);
      }
    }

    &__regular {
      background-color: #f6f7f9;
      color: $text-color;

      &:hover {
        background-color: #e5e6e8;
        color: $text-color;
      }
    }

    &__inverted {
      background-color: $white;
      border: $primary 1px solid;
      color: $primary;
      pointer-events: none;
    }

    &__expired {
      background-color: $white;
      border: $error 1px solid;
      color: $error;
      pointer-events: none;
    }

    &__small {
      padding: 0.5rem 1.5rem;

      &--no-loader {
        padding: 0.5rem 1rem;
      }

      &--text {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__medium {
      padding: 1rem 1.5rem;
    }

    &__icon {
      padding: 0;
      width: 2rem;
      height: 2rem;
    }

    + .c-btn {
      margin-left: 0.75rem;
    }
  }

  &-checkbox {
    transform: scale(1.5);
    margin-right: 6px;
    margin-left: 4px;

    &__label {
      color: #737a91;
    }

    &__label,
    &__label * {
      text-transform: initial;
      font-size: 1rem;
    }
  }

  &-switch {
    position: relative;
    display: inline-block;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .c-checkbox__label {
      margin-left: 65px;
      line-height: 34px;
      font-weight: bold;
      cursor: pointer;
      color: $text-dark;
    }
  }

  &-slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    width: 60px;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + &-slider {
    background-color: $primary;
  }

  input:focus + &-slider {
    box-shadow: 0 0 1px $primary-1;
  }

  input:checked + &-slider::before {
    transform: translateX(26px);
  }

  &-upload {
    position: absolute !important;
    width: 100%;
    height: 100% !important;
    min-width: unset !important;
    top: 0;
    opacity: 0;
    z-index: 2;

    &__container {
      position: relative;
      padding-bottom: 1rem;
      top: 0;
    }

    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $silver;
      border: 1px dashed $primary-1;
      border-radius: 8px;
      padding: 1rem;
      padding-bottom: 0;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &--wrapper {
        background-color: $white;
        border-radius: 8px;
        padding: 0.5rem;
        height: 96px;
        width: 100%;
        position: relative;

        &.default {
          @include flex-center;

          img {
            height: 32px;
            width: 32px;
          }
        }
      }

      &--close {
        background-color: $white;
        border-radius: 50%;
        padding: 3px;
        position: absolute;
        width: 30px;
        height: 30px;
        right: 0.75rem;
        top: 0.75rem;
        z-index: 2;
        cursor: pointer;
        transition: 200ms linear;

        &:hover {
          background-color: $silver;
        }
      }
    }

    &__text {
      text-align: center;
      margin: 1.5rem 0;
      max-width: 180px;

      &.loading {
        .c-upload__icon {
          @extend .c-btn__loader;

          margin: 0;
        }

        .c-upload__description {
          opacity: 0.5;
        }
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__description {
      margin-bottom: 0;
      position: relative;
      transition: 200ms linear;

      img {
        display: none;
        position: absolute;
        margin: auto;
      }

      span:first-child {
        color: $text-dark;
      }
    }
  }
}

.form {
  &-group {
    &__half {
      flex: 0 0 100%;
      max-width: 100%;

      @include tablet {
        flex-basis: 49%;
        max-width: 49%;
      }
    }
  }

  &-meta {
    color: #1f3a33;
  }

  &-row {
    margin-left: -1rem;
    margin-right: -1rem;

    > .col,
    > [class*='col-'] {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
