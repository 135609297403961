.analytics {
  &-card {
    height: 110px;
    width: 49%;
  }

  &-text {
    padding: 0;
  }

  &-title {
    color: $text-color;
    font-size: 12px;
  }

  &-doughnut {
    width: 160px;
    height: 160px;
  }

  &-appointment-chart {
    width: 100%;
    padding: 1rem;
  }

  &-customer-rating {
    width: 1rem;
  }
}
