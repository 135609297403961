* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  background: $white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  color: $text-color;
}

html,
body {
  font-size: 14px;

  @include tablet {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  letter-spacing: 1.03px;
  color: $text-dark;
  font-family: $font-family;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

small,
.small {
  font-size: 85%;
}

:focus {
  outline: none;
  box-shadow: none !important;
}

b {
  font-weight: bolder;
}

strong {
  font-weight: bold;
}
