.c-modal__footer {
  border-top: none;
}

.modal-header {
  border-bottom: none;
  padding: 12px 16px 0;

  > .c-modal__title {
    padding-bottom: 0 !important;
    color: $text-dark;
    letter-spacing: 0;
  }
}

.modal-body {
  padding-top: 0;
  padding-bottom: 5px;
}

.p-3 {
  padding-left: 0 !important;
  padding-bottom: 1.8rem !important;
  padding-top: 0.3rem !important;
  color: $text-color;
}

.modal-content {
  border: 1px solid #d4e9e2;
  border-radius: 16px;
  padding: 0.5rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    width: 440px;
  }
}
