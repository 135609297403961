.d {
  &__box {
    border-radius: 100px;
    border: 1px solid $border-color;
    padding: 4px 0;
    height: 2rem;
    background-color: $primary;

    button.dropdown-toggle {
      font-size: 14px !important;
      line-height: 1.45rem;
      color: $white !important;
      padding: 0 1rem;

      &:active,
      &:focus,
      &:hover {
        background-color: transparent !important;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-item {
      padding: 1rem;
      min-width: 100px;
    }
  }
}

.service {
  &__form {
    max-width: 776px;
    margin-bottom: 1rem;
  }

  &__type {
    font-size: 16px;

    &-draft {
      color: $amber;
    }

    &-published {
      color: $primary;
    }
  }
}
